<template>
  <div :class="wrapperClass">
    <!--<div class="toggle__label toggle__label--start" @click="modelValue = false">{{translate(offLabel)}}</div>-->
    <div :class="finalControlsClass">
      <div
        v-if="labelPosition === 'start'"
        class="toggle__label toggle__label--start"
        @click="modelValue = !modelValue">
        {{ modelValue ? translate(onLabel) : translate(offLabel) }}
      </div>
      <div
        v-if="labelPosition === 'both'"
        class="toggle__label toggle__label--start"
        @click="modelValue = false">
        {{ translate(offLabel) }}
      </div>
      <div class="toggle__controls-line"
           @click="modelValue = !modelValue">
        <div class="toggle__controls-knob flex-middle flex-center"
        :class="{'bg-success': !!modelValue, 'bg-error': !modelValue}">
          <icon
            v-show="!value"
            icon="x"
            theme="bg"
            class="animate__fadeIn animate--faster" />
          <icon
            v-show="value"
            icon="check"
            theme="bg"
            class="animate__fadeIn animate--faster" />
        </div>
      </div>
      <div
        v-if="labelPosition === 'end'"
        class="toggle__label toggle__label--end"
        @click="modelValue = !modelValue">
        {{ modelValue ? translate(onLabel) : translate(offLabel) }}
      </div>
      <div
        v-if="labelPosition === 'both'"
        class="toggle__label toggle__label--end"
        @click="modelValue = true">
        {{ translate(onLabel) }}
      </div>
    </div>
    <input v-model="value" type="hidden" v-bind="inputAttrs" />
  </div>
</template>

<script>
import ToggleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/toggleInput.js";

export default {
  mixins: [ToggleInputMixin],
  props: {
    modelValue: {}, // this doesn't work from mixin. reason - unknown
  },
  data: function () {
    return {};
  },

  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss"></style>
